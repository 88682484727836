/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@300&display=swap');
:root
{
    --main-color:#0aad0a;
    --light-color:#f0f3f2;
    --shadow: rgba(145,158,171,.2) 0px 2px 4px -1px,rgba(145,158,171,.14) 0px 4px 5px 0px,rgba(145,158,171,.12)
     0px 1px 10px 0px;
    --font-family:'Encode Sans Expanded', sans-serif;
    --rating-color:#ffc908;
}

body{
    font-family: var(--font-family) !important;
}
.rating-color
{
    color: var(--rating-color);
}
.cursor-pointer
{
    cursor: pointer;
}
a , a:hover{
    color: black;
    text-decoration: none;
}
.product:hover
{
    box-shadow: var(--shadow);
    border: solid 1px var(--main-color);
}
.product .btn 
{
    transition: transform 1s , opacity 1s;
    transform: translateY(100%);
    opacity: 0;
}
.product:hover .btn 
{
    opacity: 1;
    transform: translateY(0%);

}
.font-sm
{
    font-size: 14px;
}
.text-main
{
    color: var(--main-color);
}
.bg-main
{
    background-color: var(--main-color) !important;
}
.bg-main-light 
{
    background-color: var(--light-color) !important;
}

.loading{
    z-index: 405;
}

/* ---------------------------------------------------------------------------------------------------- */
/* Class active l bootstrap */

.active{
    color: var(--main-color) !important;
    font-weight: 900;
}

/* ---------------------------------------------------------------------------------------------------- */
/*loading screen of home component */

.loader {
    width: 100%;
    height: 4.8px;
    display: inline-block;
    position: relative;
    background: rgb(255, 255, 255);
    overflow: hidden;
  }
  .loader::after {
    content: '';
    width: 192px;
    height: 4.8px;
    background: var(--main-color);
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animloader 2s linear infinite;
  }
  
  @keyframes animloader {
    0% {
      left: 0;
      transform: translateX(-100%);
    }
    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }

/* ---------------------------------------------------------------------------------------------------- */
/* adjustment of images in home slider */

.imgHight{
    height: 372px;
}
.imgbord1{
    border-top-right-radius: 20px;
}
.imgbord2{
    border-bottom-right-radius: 20px;
}

/* --------------------- */

/* adjustment of images in category slider */
.imgHight2{
    height: 190px;
}

/* ---------------------------------------------------------------------------------------------------- */
/* adjustment of buttons color in sweet alert */

.swal2-confirm{
    background-color: #7f8515 !important;
    box-shadow:none !important;
} 

.swal2-cancel{
    box-shadow:none !important;
}

/* ---------------------------------------------------------------------------------------------------- */
/* adjustment of cart icon  in navbar */
.noSize{
    font-size: 10px;
    padding-left: 6px;
    padding-top: 5px;
    padding-right: 6px;
    padding-bottom: 5px;
    background-color: #3A3A3A;
    color: #F0F3F2;
}

.mySize{
    font-size: 25px;
}
/* ---------------------------------------------------------------------------------------------------- */
